<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio ticket</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Dettaglio ticket</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>

      <div v-else>
        <ion-list class="ion-padding-horizontal">
          <router-link
            :to="{
              name: 'ClienteDetail',
              params: { id: ticket.tickets_customer_id },
            }"
            style="text-decoration: none; color: inherit"
          >
            <ion-item class="ion-no-padding" button="true" detail>
              <ion-label color="primary"> Cliente </ion-label>
              <ion-text>
                <p class="base_text" style="color: #3880ff">
                  {{ riferimentoCliente(ticket) }}
                </p>
              </ion-text>
            </ion-item>
          </router-link>

          <ion-item class="ion-no-padding">
            <ion-label color="primary"> Data assegnazione </ion-label>
            <ion-text>
              <p class="base_text">
                {{ dateFormat(ticket.tickets_assign_date) || "-" }}
              </p>
            </ion-text>
          </ion-item>

          <div v-if="ticket.tickets_project_id != null">
            <router-link
              :to="{
                name: 'CommessaDetail',
                params: {
                  id: ticket.tickets_customer_id,
                  id_commessa: ticket.tickets_project_id,
                },
              }"
              style="text-decoration: none; color: inherit"
            >
              <ion-item class="ion-no-padding" button="true" detail>
                <ion-label color="primary"> Impianto </ion-label>
                <ion-text>
                  <p class="base_text" style="color: #3880ff">
                    {{ ticket.projects_codice || "-" }}
                  </p>
                </ion-text>
              </ion-item>
            </router-link>
          </div>

          <ion-item class="ion-no-padding">
            <ion-label color="primary">Titolo</ion-label>
            <ion-text>
              <p class="base_text">
                {{ checkField(ticket.tickets_subject) }}
              </p>
            </ion-text>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-label color="primary"> Categoria</ion-label>
            <ion-text>
              <p class="base_text">
                {{ checkField(ticket.tickets_category_value) }}
              </p>
            </ion-text>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-label color="primary"> Stato</ion-label>
            <ion-text>
              <ion-badge :color="statoTicket(ticket.tickets_status_id)">
                {{ ticket.tickets_status_value }}
              </ion-badge>
            </ion-text>
          </ion-item>

          <!-- <ion-item class="ion-no-padding">
            <ion-label color="primary">Operatore / referente</ion-label>
            <ion-text>
              <p class="base_text">
                {{ checkField(ticket.tickets_assign_to) }}
              </p>
            </ion-text>
          </ion-item> -->

          <ion-item class="ion-no-padding">
            <ion-label color="primary">N° tecnici previsti</ion-label>
            <ion-text>
              <p class="base_text">
                {{ checkField(ticket.tickets_num_tecnici) }}
              </p>
            </ion-text>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-label color="primary">Tecnici</ion-label>
            <ion-text v-if="ticket.tickets_tecnici">
              <div
                style="padding: 3px 0; text-align: right"
                v-for="(tecnico, index) in ticket.tickets_tecnici"
                :key="index"
              >
                {{ tecnico }}
              </div>
            </ion-text>
            <ion-text v-else> - </ion-text>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-row>
              <ion-col
                size="8"
                class="fontSize"
                style="padding-top: 10px; padding-left: 0"
              >
                <ion-label color="primary">Descrizione</ion-label>
              </ion-col>
              <ion-col
                size="12"
                style="font-size: 14px; padding-left: 0; padding-right: 0"
              >
                {{ parseHtmlEnteties(checkField(ticket.tickets_message)) }}
              </ion-col>
            </ion-row>
          </ion-item>
        </ion-list>
        <div class="btn_action">
          <ion-button
            v-show="!created"
            color="success"
            size="default"
            expand="block"
            @click="openModal"
          >
            Crea intervento
          </ion-button>
          <ion-button
            v-show="created"
            color="success"
            size="default"
            expand="block"
            disabled
          >
            Crea Intervento
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  /*IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,*/
  IonLabel,
  IonList,
  IonItem,
  IonBadge,
  IonButton,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";
import { add } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import moment from "moment";

import apiTicket from "../services/ticket";
import apiMateriali from "../services/materiali";
import { openToast } from "../services/toast";

import Modal2 from "../components/Modal2.vue";

export default {
  name: "TicketDetail",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    /*IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,*/
    IonLabel,
    IonList,
    IonItem,
    IonBadge,
    IonButton,
    IonRow,
    IonCol,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id as string; //ticket_id by route params
    const loading = ref<boolean>(false);
    const ticket = ref([]);
    const materiali = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const router = useRouter();

    const created = ref(false); //flag per disabilitare button crea intervento nel caso in cui utente torni indietro da dettaglio intervento
    //const localInterventi = JSON.parse(localStorage.getItem("interventi"));

    /**
     *
     * Open modal to create new intervento
     *
     */
    async function openModal() {
      const modal = await modalController.create({
        component: Modal2, //Component to render inside ionic modal
        componentProps: {
          cliente: ticket.value,
          impianti: ticket.value,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          //insert new record and update localstorage
          //interventi.value.push(detail.data);
          //const interventiTotali = JSON.stringify(interventi.value);
          //localStorage.setItem("interventi", interventiTotali);

          //Disable Crea intervento button if user go back from the newly created intervento
          created.value = true;
          openToast("Nuovo intervento inserito", "success");
          router.push(`/tabs/tab2/${detail.data.tickets_reports_id}`);
        }
      });
      return modal.present();
    }

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    const riferimentoCliente = computed(() => {
      return (ticket) => {
        if (ticket.customers_name && ticket.customers_last_name) {
          return `${ticket.customers_name} ${ticket.customers_last_name}`;
        } else {
          return `${ticket.customers_company}`;
        }
      };
    });

    /**
     *
     * Set correct background for ticket status
     *
     */
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          className = "secondary";
        } else if (statusId == 2) {
          className = "warning";
        } else if (statusId == 3) {
          className = "tertiary";
        } else if (statusId == 4) {
          className = "danger";
        } else {
          className = "success";
        }
        return className;
      };
    });

    /**
     *
     * Return '-' if field is null
     *
     */
    function checkField(field) {
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     *
     * Return '-' if tecnici is null
     *
     */
    function checkTecnici(tecnici) {
      if (tecnici == null || tecnici == "") {
        return "-";
      } else {
        return tecnici;
      }
    }

    /**
     *
     * Remove html tag from ticket description
     *
     */
    function parseHtmlEnteties(str) {
      const strippedString = str.replace(/(<([^>]+)>)/gi, "");
      return strippedString.replace(
        /&#([0-9]{1,3});/gi,
        function (match, numStr) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        }
      );
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    function getMateriali(codice_commessa: string) {
      apiMateriali
        .getMateriali(codice_commessa)
        .then((response) => {
          //console.log(response);
          materiali.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta dei materiali", "danger");
          console.error(error);
        })
        .finally(() => {
          //console.log("fine");
        });
    }

    onMounted(() => {
      //GET TICKET DATA
      loading.value = true;
      apiTicket
        .getTicketDetail(id, userID)
        .then((response) => {
          ticket.value = response;
          //if ticket status is lavoro completato disable button
          if (ticket.value["tickets_status_id"] == "5") {
            created.value = true;
          }
          //getMateriali(response.tickets_project_id);
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta del ticket", "danger");
        })
        .finally(() => {
          loading.value = false;
          //console.log("fine");
        });
    });

    return {
      loading,
      ticket,
      riferimentoCliente,
      dateFormat,
      checkField,
      parseHtmlEnteties,
      add,
      statoTicket,
      //Handle new intervento
      openModal,
      //flag for disabling button
      created,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base_text {
  font-size: 14px;
  /*color: #000000 !important;*/
}
.pb-0 {
  padding-bottom: 0;
}

.description_title {
  color: var(--ion-color-primary, #3880ff) !important;
  margin: 10px 0;
}

.bg-primary {
  background: var(--ion-color-primary, #3880ff) !important;
  padding: 8px;
  color: #ffffff;
}

.card_title {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.badge_link {
  background: #c2d3f4;
  padding: 3px 6px;
  border-radius: 3px;
}

.btn_action {
  padding: 10px 50px;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}
</style>
import axios from 'axios';
const TOKEN = 'Bearer 26d460b39301781b10d37af31e2e5a4a';

export default {

    async getMateriali(projectID: string) {
        const data = new FormData();
        data.append("where[projects_billing_items_project]", projectID.toString());

        const response = await axios.post('/search/projects_billing_items', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    }
}